import { parsePayload } from "./jwt";

export const Roles = {
  READ: "a729bbd4-2038-4649-9127-16782bb1e701",
  CREATE: "efcc3025-e2d8-4212-8227-805c7be39d2c",
  DELETE: "8f78dce7-f5f9-4033-a10c-c9c7408bfcfe",
  CERTIFICATES: "746f34b0-9ba0-4b5d-8d84-0256a9c8e390",
  APPROVESUPPLIERS: "a6c9805e-80b2-42b2-bfbb-9df52e5504d8",
};

export const hasRoleToken = (roles, token) => {
  if (!roles || roles.length === 0) return true;

  const groups = getGroups(token);

  if (!groups) return false;

  return hasRole(roles, groups);
};

export const getGroups = (token) => {
  const payload = parsePayload(token);

  if (!payload || !payload["custom:groups"]) return null;

  return payload["custom:groups"];
};

export const hasRole = (roles, groups) => {
  if (!roles || roles.length === 0) return true;
  if (!groups) return false;

  for (let role of roles) {
    if (groups.indexOf(role) > -1) return true;
  }

  return false;
};
