import React from "react";
import { List } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import CommuteIcon from "@material-ui/icons/Commute";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AssessmentIcon from "@material-ui/icons/Assessment";
import BuildIcon from "@material-ui/icons/Build";
import SettingsInputCompositeIcon from "@material-ui/icons/SettingsInputComposite";

import ListItemLink from "../ListItemLink";
import ListItemExternalLink from "../ListItemExternalLink";
import { useUserContext } from "../Contexts/UserContext";
import { Roles, hasRole } from "../../utils/roles";

const menuData = [
  {
    label: "Home",
    to: "/home",
    icon: <HomeIcon />,
    roles: [],
  },
  {
    label: "Deployments",
    to: "/packages",
    icon: <CloudDownloadIcon />,
    roles: [Roles.READ, Roles.CREATE],
  },
  {
    label: "Vehicles",
    to: "/vehicles",
    icon: <DirectionsCarIcon />,
    roles: [Roles.READ, Roles.CREATE],
  },
  {
    label: "Fleets",
    to: "/fleets",
    icon: <CommuteIcon />,
    roles: [Roles.READ, Roles.CREATE],
  },
  {
    label: "Datascope",
    to: "/datascope",
    icon: <AssessmentIcon />,
    roles: [Roles.READ, Roles.CREATE],
  },
  {
    label: "Suppliers",
    to: "/suppliers",
    icon: <SettingsInputCompositeIcon />,
    roles: [Roles.APPROVESUPPLIERS],
  },
  {
    label: "Tools",
    to: "/tools/certificates/add",
    icon: <BuildIcon />,
    roles: [Roles.CERTIFICATES, Roles.APPROVESUPPLIERS],
    submenus: [
      {
        label: "Certificate",
        to: "/tools/certificates/add",
        roles: [Roles.CERTIFICATES],
      },
      {
        label: "SMS",
        to: "/tools/sms/send",
        roles: [Roles.CREATE],
      }
    ],
  },
];

const MenuItem = ({ item, children }) => {
  return (
    <li>
      {item.to && (
        <ListItemLink primary={item.label} to={item.to} icon={item.icon} />
      )}
      {item.url && (
        <ListItemExternalLink
          primary={item.label}
          url={item.url}
          icon={item.icon}
        />
      )}
      {children}
    </li>
  );
};

const ExpandableSideMenuItem = ({ item }) => (
  <>
    <span>
      <MenuItem item={item}></MenuItem>
    </span>
    <ul style={{ marginLeft: 50 }}>
      {item.submenus.map((subitem, index) => (
        <MenuItem key={`submenu-${index}`} item={subitem} />
      ))}
    </ul>
  </>
);

const SideMenu = () => {
  const { groups } = useUserContext();
  const menu = menuData.reduce((result, item) => {
    if (hasRole(item.roles, groups)) {
      result.push(item);
    }

    return result;
  }, []);

  return (
    <List>
      {menu.map((item, index) => {
        const key = `menu-${index}`;
        if (item.submenus)
          return <ExpandableSideMenuItem key={key} item={item} />;
        return <MenuItem key={key} item={item} />;
      })}
    </List>
  );
};

export default SideMenu;
