import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

import { logger } from "../services/monitoring";

const reload = () => {
  window.location.reload();
};
export default class ErrorBoundary extends Component {
  state = {
    error: "",
    errorInfo: "",
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
  }

  render() {
    if (this.state.hasError) {
      try {
        logger.error(this.state.error.stack);
      } catch (e) {}

      if (this.state.error && this.state.error.name === "ChunkLoadError") {
        reload();
        return;
      }

      return (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography variant="h3" align="center">
            Sorry, an error has occured and been logged
          </Typography>
          <Typography
            variant="h5"
            align="center"
            style={{
              cursor: "pointer",
              textDecorationColor: "Blue",
              textDecorationStyle: "solid",
              textDecorationLine: "underline",
              color: "Blue",
            }}
            onClick={reload}
          >
            Click to reload
          </Typography>
        </div>
      );
    }
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
