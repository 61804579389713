import React, { Suspense } from "react";
import { Switch } from "react-router-dom";

import { AuthRoute, TYPES } from "../Routes/AuthRoute";
import { MessageBar } from "../MessageBar";
import { useUserContext } from "../Contexts/UserContext";
import { Roles } from "../../utils/roles";

const CANFilterCreate = React.lazy(() => import("../CANFilter/Add"));
const CANFilterUpdate = React.lazy(() => import("../CANFilter/Update"));
const CarsList = React.lazy(() => import("../Cars/List"));
const CarStatus = React.lazy(() => import("../Cars/Status"));
const CarUpdateStatus = React.lazy(() => import("../Cars/UpdateStatus"));
const FleetsList = React.lazy(() => import("../Fleets/Table"));
const FleetStatus = React.lazy(() => import("../Fleets/Status"));
const FleetAddForm = React.lazy(() => import("../Fleets/Add"));
const FleetUpdateForm = React.lazy(() => import("../Fleets/Update"));
const FleetAddVehicleForm = React.lazy(() =>
  import("../Fleets/Status/Vehicles/Add")
);
const FleetAddCANFilterForm = React.lazy(() =>
  import("../Fleets/Status/CANFilters/Add")
);
const FleetUpdateCANFilterForm = React.lazy(() =>
  import("../Fleets/Status/CANFilters/Update")
);
const Home = React.lazy(() => import("../Home"));
const Manifests = React.lazy(() => import("../Manifest/List"));
const ManifestDeploy = React.lazy(() => import("../Manifest/Deploy"));
const ManifestStatus = React.lazy(() => import("../Manifest/Status"));
const PageNotFound = React.lazy(() => import("../404"));
const SSOForm = React.lazy(() => import("../SSOForm"));
const VehicleAddForm = React.lazy(() => import("../Cars/Add"));
const VehicleUpdateForm = React.lazy(() => import("../Cars/Update"));
const CertificateCreate = React.lazy(() => import("../Certificates/Add"));
const SMSSend = React.lazy(() => import("../SMS/Send"));
const SuppliersList = React.lazy(() => import("../Suppliers/List"));
const SupplierDetails = React.lazy(() => import("../Suppliers/Details"));
const Datascope = React.lazy(() => import("../Dashboard"));
const SiteRoutes = () => {
  const { token, groups } = useUserContext();
  return (
    <Suspense fallback={"Loading..."}>
      <MessageBar />
      <Switch>
        <AuthRoute
          path="/"
          exact
          render={() => <SSOForm />}
          type={TYPES.GUEST}
          token={token}
        />
        <AuthRoute
          path="/filter-add"
          render={() => <CANFilterCreate />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.CREATE]}
        />
        <AuthRoute
          path="/filter-update"
          render={() => <CANFilterUpdate />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.CREATE]}
        />
        <AuthRoute
          path="/fleets"
          render={() => <FleetsList />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.READ, Roles.CREATE]}
        />
        <AuthRoute
          path="/fleet/:name/vehicle-add"
          render={() => <FleetAddVehicleForm />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.READ, Roles.CREATE]}
        />
        <AuthRoute
          path="/fleet/:name/filter-add"
          render={() => <FleetAddCANFilterForm />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.READ, Roles.CREATE]}
        />
        <AuthRoute
          path="/fleet/:name/filter-update"
          render={() => <FleetUpdateCANFilterForm />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.READ, Roles.CREATE]}
        />
        <AuthRoute
          path="/fleet/:name"
          render={() => <FleetStatus />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.READ, Roles.CREATE]}
        />
        <AuthRoute
          path="/fleet-add"
          render={() => <FleetAddForm />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.CREATE]}
        />
        <AuthRoute
          path="/fleet-update"
          render={() => <FleetUpdateForm />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.CREATE]}
        />
        <AuthRoute
          path="/home"
          render={() => <Home />}
          type={TYPES.PROTECTED}
          token={token}
        />
        <AuthRoute
          path="/packages"
          render={() => <Manifests />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.READ, Roles.CREATE]}
        />
        <AuthRoute
          path="/package-deploy/:manifest_id"
          render={() => <ManifestDeploy />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.CREATE]}
        />
        <AuthRoute
          path="/package-status/:manifest_id"
          render={() => <ManifestStatus />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.READ, Roles.CREATE]}
        />
        <AuthRoute
          path="/vehicles"
          render={() => <CarsList />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.READ, Roles.CREATE]}
        />
        <AuthRoute
          path="/vehicle-add"
          render={() => <VehicleAddForm />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.CREATE]}
        />
        <AuthRoute
          path="/vehicle-status/:vin/:carupdateid"
          render={() => <CarUpdateStatus />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.READ, Roles.CREATE]}
        />
        <AuthRoute
          path="/vehicle-status/:vin"
          render={() => <CarStatus />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.READ, Roles.CREATE]}
        />
        <AuthRoute
          path="/vehicle-update"
          render={() => <VehicleUpdateForm />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.CREATE]}
        />
        <AuthRoute
          path="/tools/certificates/add"
          render={() => <CertificateCreate />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.CERTIFICATES]}
        />
        <AuthRoute
          path="/tools/sms/send"
          render={() => <SMSSend />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.CREATE]}
        />
        <AuthRoute
          path="/suppliers"
          render={() => <SuppliersList />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.APPROVESUPPLIERS]}
        />
        <AuthRoute
          path="/supplier/:email"
          render={() => <SupplierDetails />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.APPROVESUPPLIERS]}
        />
        <AuthRoute
          path="/datascope"
          render={() => <Datascope />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          roles={[Roles.READ, Roles.CREATE]}
        />
        <PageNotFound />
      </Switch>
    </Suspense>
  );
};

export default SiteRoutes;
